import React from 'react'
import { useField } from 'formik'

interface TextInputProp
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
  id: string
  showError?: boolean
  caption?: string
}

export const TextInput = ({
  label,
  showError = true,
  id,
  caption,
  ...props
}: TextInputProp): JSX.Element => {
  const [field, meta] = useField(id)
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        className={
          'appearance-none transition duration-150 ease-in-out border border-gray-300 hover:border-gray-500 focus:outline-none focus:shadow-outline focus:border-gray-900 rounded p-2 w-full placeholder-gray-600 appearance-none' +
          (showError && meta.touched && meta.error
            ? ' border-red-600 hover:border-red-800'
            : null)
        }
        {...field}
        {...props}
      />
      {caption && <div className="text-xs">{caption}</div>}
      <div className="h-6 text-red-600 text-xs mb-4">
        {showError && meta.touched && meta.error}
      </div>
    </>
  )
}

export const TextArea = ({
  label,
  showError = true,
  id,
  caption,
  ...props
}: {
  label: string
  id: string
  name?: string
  placeholder?: string
  type?: string
  showError?: boolean
  caption?: string
}): JSX.Element => {
  const [field, meta] = useField(id)
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        className={
          'transition duration-150 ease-in-out border border-gray-300 hover:border-gray-500 focus:outline-none focus:shadow-outline focus:border-gray-900 rounded p-2 w-full placeholder-gray-600 appearance-none' +
          (showError && meta.touched && meta.error
            ? ' border-red-600 hover:border-red-800'
            : null) +
          ' align-top' //Removing extra bottom whitespace from textarea
        }
        {...field}
        {...props}
      />
      {caption && <div className="text-xs">{caption}</div>}
      <div className="h-6 text-red-600 text-xs mb-4">
        {showError && meta.touched && meta.error}
      </div>
    </>
  )
}
