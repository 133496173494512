import React from 'react'
import Debug from '../../components/debug'

export default function Devtool({ data }: { data?: any }) {
  const handleClick = () => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    ).set
    const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value',
    ).set
    const email = document.querySelector('#email')
    const subject = document.querySelector('#subject')
    const description = document.querySelector('#description')

    nativeInputValueSetter.call(email, 'test@mail.com')
    nativeInputValueSetter.call(subject, 'development testing')
    nativeTextAreaValueSetter.call(
      description,
      'sample description input\nhope all will be fine',
    )
    const inputEvent = new Event('input', { bubbles: true })
    email.dispatchEvent(inputEvent)
    subject.dispatchEvent(inputEvent)
    description.dispatchEvent(inputEvent)
    subject.focus()
    setTimeout(() => {
      subject.blur()
    }, 1)
  }

  return (
    <section
      className="font-sans bg-white w-84 h-auto shadow fixed p-4 rounded border overflow-scroll"
      style={{
        top: 100,
        right: 50,
        bottom: 30,
      }}
    >
      <h1 className="text-lg font-bold mb-4">Dev Tools</h1>
      <p className="text-gray-700">Fill form</p>
      <button
        type="button"
        className="select-none px-4 font-bold py-1 rounded text-white text-center transition duration-150 ease-in-out bg-blue-700 hover:bg-blue-600 active:bg-blue-800 focus:outline-none focus:shadow-outline disabled:bg-blue-300 disabled:cursor-not-allowed uppercase text-sm"
        onClick={handleClick}
      >
        fill
      </button>
      <hr className="my-4" />
      <Debug data={data} />
    </section>
  )
}
