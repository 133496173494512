import React from 'react'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import withQueryString from '../utils/with-query-string'
import FeedbackForm from '../page-specific/feedback/feedback-form'
import SEO from '../components/seo'
import { useLiff } from '../utils/liff'

type WithQueryStringType = {
  queryString: {
    liff?: string
    devtool?: string
  }
}

const FeedbackPage = ({ queryString }: WithQueryStringType): JSX.Element => {
  const isOpenDevtools =
    queryString.devtool && process.env.GATSBY_ENVIRONMENT === 'development'

  const { loading, profile, error } = useLiff({ loadLanguage: true })

  if (loading) {
    return <div className="max-w-3xl mx-auto p-6">loading</div>
  }

  if (!profile) {
    return (
      <LayoutWithHeaderAndFooter>
        <SEO title="Feedback" />
        <div className="max-w-3xl mx-auto">
          <FeedbackForm isOpenDevtool={isOpenDevtools} />
        </div>
      </LayoutWithHeaderAndFooter>
    )
  }

  if (error) {
    return <span className="text-red-600">Error: {error}</span>
  }

  return (
    <>
      <SEO title="Feedback" />
      <div className="max-w-3xl mx-auto p-6">
        <FeedbackForm lineId={profile?.userId} isOpenDevtool={isOpenDevtools} />
      </div>
    </>
  )
}

export default withQueryString(FeedbackPage)
