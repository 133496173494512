import React from 'react'
import queryString, { ParsedQuery } from 'query-string'

type Props = {
  [prop: string]: any
}
type WithQueryStringType = {
  queryString: ParsedQuery
}
type PageLevelProps = {
  location: {
    search: string
  }
}

export default function withQueryString<P extends Props>(
  Component: React.ComponentType<P & WithQueryStringType>,
) {
  return function ComponentWithQueryString(
    props: P & PageLevelProps,
  ): React.ReactElement<P & WithQueryStringType> {
    const { location } = props
    return (
      <Component queryString={queryString.parse(location.search)} {...props} />
    )
  }
}
