import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'gatsby'

import { DisplayLarge, HeadingLarge } from '../../components/typography'
import SubmitButton from '../../components/RegistrationForm/formComponents/SubmitButton'
import { TextInput, TextArea } from '../../components/forms'
import Devtool from './devtool'
import { callApi, ApiService } from '../../utils/api'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'

type FormDataType = {
  email: string
  subject: string
  description: string
  lineId?: string
}

const validationSchema = ({
  email = 'Invalid email address',
  subject = 'Please specify subject',
  description = 'Please specify feedback’s description',
}) =>
  Yup.object({
    email: Yup.string().email(email),
    subject: Yup.string().required(subject),
    description: Yup.string().required(description),
  })

const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object

const formEmptyValue = {
  email: '',
  subject: '',
  description: '',
}

async function sendFeedback(data: FormDataType): Promise<void> {
  try {
    await callApi({
      service: ApiService.feedback,
      body: data,
    })
  } catch (error) {
    console.error(error)
    alert('Sorry something went wrong, please try again later')
    throw error
  }
}

export default function FeedbackForm({
  lineId,
  isOpenDevtool,
}: {
  lineId?: string
  isOpenDevtool: boolean
}): JSX.Element {
  const [initialFormData, setInitialFormData] = React.useState<FormDataType>(
    formEmptyValue,
  )
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false)
  const { t } = useTranslation(Namespace.Feedback)

  if (formSubmitted) {
    return (
      <div
        className={`text-center flex justify-center flex-col items-center content-center`}
      >
        <DisplayLarge as="h1" className="mb-4">
          {t('success.thank')}
        </DisplayLarge>
        <p className="text-lg mb-12">{t('success.description')}</p>

        <SubmitButton
          type="button"
          compact="sm"
          onClick={() => {
            setFormSubmitted(false)
          }}
        >
          {t('success.sendMoreButton')}
        </SubmitButton>
        <Link
          className="select-none px-6 font-bold p-2 rounded text-center transition duration-150 ease-in-out hover:bg-gray-200 active:bg-gray-300 focus:outline-none focus:shadow-outline mt-2"
          to="/"
        >
          {t('success.backToHomeButton')}
        </Link>
      </div>
    )
  }

  return (
    <>
      <DisplayLarge as="h1" className="mb-1">
        {t('title')}
      </DisplayLarge>
      <HeadingLarge className="mb-12">{t('subtitle')}</HeadingLarge>
      <Formik
        initialValues={initialFormData}
        validationSchema={validationSchema({
          email: t('form.email.errorMessage'),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          await sendFeedback({ ...values, lineId })

          setFormSubmitted(true)

          setInitialFormData({
            ...formEmptyValue,
            email: values.email,
          })
        }}
      >
        {(formik) => (
          <Form>
            {isOpenDevtool && <Devtool data={formik} />}
            <TextInput
              id="email"
              label={t('form.email.label')}
              name="email"
              type="email"
              placeholder="example@gmail.com"
              caption={t('form.email.caption')}
            />
            <TextInput
              id="subject"
              label={t('form.subject.label')}
              name="subject"
              type="text"
              placeholder={t('form.subject.placeholder')}
              showError={false}
            />
            <TextArea
              id="description"
              label={t('form.description.label')}
              name="description"
              placeholder={t('form.description.placeholder')}
              showError={false}
            />
            <SubmitButton
              isDisabled={
                isEmptyObject(formik.touched) ||
                !formik.isValid ||
                formik.isSubmitting
              }
              compact="sm"
              className="flex justify-center ml-auto mt-6"
            >
              {t('form.submitButton.label')}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  )
}
